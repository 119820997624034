import Box from '@mui/material/Box'
import Head from 'next/head'
import dynamic from 'next/dynamic'

const NotFound = dynamic(() => import('../components/notFound'), { ssr: false })

const Custom404 = props => (
  <Box>
    <Head>
      <title>404 | Renty.AI</title>
      <meta data-n-head="ssr" name="description" content="This page cannot be found." />
      <link rel="canonical" href="https://renty.ai/404" />
    </Head>
    <NotFound {...props} />
  </Box>
)

export default Custom404
